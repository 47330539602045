/* General styles */

.headerandinput {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.header {
  font-size: 50px;
  text-align: center;
  color: black;
  margin-right: 84px;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.react-select__control {
  width: 100%; /* Full width of .input-container */
  cursor: text;
  /* ... */
}


.suggestion-container {
  display: flex;
  justify-content: space-between; /* Adjust spacing */
  align-items: center;
  width: 100%;
}

.versus {
  white-space: nowrap; /* Prevent "VS" from wrapping */
  padding: 0 10px; /* Adjust based on space availability */
}

.no-bullets {
  color: black;
  list-style-type: none;
  padding-left: 0;
  position: absolute;
  width: calc(100% - 4px); /* Adjust width to match input field, considering border */
  background-color: #fff;
  border: 2px solid #ddd; /* Add a border to match input fields */
  border-top: none; /* Remove top border to seamlessly connect with the input */
  z-index: 1;
  max-height: 150px;
  overflow-y: auto; /* Allow scrolling within the list */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for depth */
}


/* Input and button styles */
.suggestion-container input,
.compare-button {
  width: 100%; /* Full width to match parent's size */
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 16px; /* Readable font size for most screens */
  margin-top: 10px;
}

.suggestion-container input:focus,
.compare-button:focus {
  outline: none;
  border-color: #FF4081;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.compare-button {
  background-color: #FF4081;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  padding: 12px 25px;
  font-size: 18px; /* Slightly larger font for the button */
}

.compare-button:hover {
  background-color: #ff79b0;
}


@media (max-width: 768px) {
  .header {
    font-size: 30px; /* Smaller font size for smaller screens */
    margin-bottom: 15px;
  }
  .input-container {
    padding: 10px; /* Less padding on smaller screens */
    width: 90%; /* Use more of the screen width */
    max-width: none; /* Override max-width set for larger screens */
  }
  .compare-button, .suggestion-container input {
    font-size: 14px; /* Smaller font size for readability */
  }
  .versus {
    font-size: 18px; /* Adjust according to space */
    margin: 10px 0; /* Stack vertically with space */
  }
  .suggestion-container {
    flex-direction: column; /* Stack inputs vertically */
    align-items: center;
  }
}