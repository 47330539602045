.win-lose-comparison-box{
    display: flex;
  justify-content:space-around;
  margin: 40px;
  margin-left:0px;
  margin-bottom: 100px;
  text-align: center;
}


.player1-wins {
    width: 200px; /* Adjust the width as needed */
    height: 100px; /* Adjust the height as needed */
    display: block;
    color: #FF4081;
    border-radius: 10px;
    font-size: 100px;
  
  }
  
  .player2-wins {
    width: 200px; /* Adjust the width as needed */
    height: 100px; /* Adjust the height as needed */
    display: block;
    color: #FF4081;
    border-radius: 10px;
    font-size: 100px;
  
  }