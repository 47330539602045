  .player-comparison {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #EEEEEE;
    color: #000;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    width: auto;
    max-width: 900px; /* Use max-width for scalability */
    margin: auto;
  }
.player-comparison h1 {
  font-size: 60px;
}

.player-comparison h2 {
  font-size: 40px;
}
  .App {
    color:#333333;
  }

  .player-comparison table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom:30px;
  }

  .player-comparison th, .player-comparison td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }

  .player-comparison th {
    background-color: #ff79b0;
    color: white;
  }

  .player-comparison h1, .player-comparison h2, .player-comparison h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  .player-comparison .button-link {
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #e0e0e0;
    color: #000;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  .player-comparison .button-link:hover {
    background-color: #d4d4d4;
  }

  @media (max-width: 768px) {
    .player-comparison {
      padding: 10px;
    }

    .player-comparison table, .player-comparison th, .player-comparison td {
      font-size: 14px; /* Smaller font size for small screens */
      padding: 5px; /* Reduced padding */
    }

    .player-comparison th, .player-comparison td {
      padding: 4px; /* Even smaller padding */
    }

    .player-comparison table {
      overflow-x: auto; /* Make table scrollable horizontally */
      display: block;
    }
  }
