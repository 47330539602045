/* AccountScreen.css */

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
}
.feedback-message {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    text-align: center;
}

.positive {
    background-color: lightgreen;
}
.google {
    margin:10px;
    border-radius: 10px;
    border-color: transparent;
    cursor: pointer;
}
.negative {
    background-color: lightcoral;
}

.logo {
    width: 330px;
    height: 350px;
    margin:auto;
    display: block;
    background-image:url('img/logo3.png');
    background-size:contain;    ;
}
.full-page-background {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    background-image: url('img/bakgrunn.webp'); /* Correct path to your background image */
    background-position: center;
    background-size: cover;
    margin: 0; /* Ensure there is no margin */
}

.container-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Distribute space between the containers */
    align-items: center; /* Center align containers on all screens */
    text-align: center; /* Center-align text content */
}

.registration-container,
.feature-container {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    width: calc(50% - 20px); /* Adjust width for larger screens */
    max-width: 400px; /* Set a maximum width for larger screens */
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.feature-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.feature-item p {
    font-size: 14px;
    line-height: 1.4;
}

.inputContainer,
.buttonContainer {
    margin: 10px 0;
}

.input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.button {
    background-color: #005e74;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.button:hover {
    background-color: #005e74;
}

/* Media query for smaller screens (e.g., mobile devices) */
@media screen and (max-width: 768px) {
    .container-wrapper {
        flex-direction: column; /* Stack containers vertically on mobile screens */
    }

    .registration-container,
    .feature-container {
        width: 100%; /* Take up full width on mobile screens */
    }
}
