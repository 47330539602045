@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #F5F5F5; /* Updated to white */
  color: #000; /* Updated to black */
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

  .player-list {
    list-style-type: none;
    padding-left: 0;
    top: 40px; /* Adjust the value as needed */
  }
  
  

  .player-item {
    margin-bottom: 5px;
  }

  .PlayerList h1 {
    font-size: 40px;
    margin-bottom: 10px;
    text-align: center;
    color: #000; /* Updated to black */
  }

  .PlayerList input {
    padding: 20px;
    margin-bottom: 0px;
    border-radius: 4px;
    border: none;
    background-color: #40444b;
    color: #000000;
    width: 600px; /* Adjust the width as needed */
  }

  .PlayerList input::placeholder {
    color: #8e9297;
  }

  .PlayerList h2 {
    font-size: 20px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    background-color: #36393f;
    padding: 10px 0;
  }

  .PlayerList a {
    color: #00aaff; /* Change the color to your preferred color */
    text-decoration: none;
  }

  /* Changelog component */
  .Changelog {
    margin-top: 20px;
  }

  .Changelog h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .Changelog ul {
    padding-left: 20px;
  }

  .Changelog li {
    margin-bottom: 5px;
  }

.header {
  margin-top: 100px;
  padding-left: 93px;
}

  /* Flying rackets */
  .flying-rackets {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    overflow: hidden;
    z-index: 1;  /* Lower than the main content */
}

  .racket {
    position: absolute;
    width: 80px;  /* Adjust size of emoji if needed */
    height: 80px;  /* Adjust size of emoji if needed */
    font-size: 80px;  /* Adjust size of emoji if needed */
    animation: flyAndRotate 5s linear infinite;
  }

  @keyframes flyAndRotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
      transform: translate(150%, 150%) rotate(360deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(720deg);
    }
  }

.rank {
  width: 200px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  display: block;
  color: #FF4081;
  border-radius: 10px;
  font-size: 100px;

}

.points {
  width: 200px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  display: block;
  color: #FF4081;
  border-radius: 10px;
  font-size: 100px;

}

.best-placement {
  width: 200px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  display: block;
  color: #FF4081;
  border-radius: 10px;
  font-size: 100px;

}


.rank-box {
  display: flex;
  justify-content:space-around;
  margin: 40px;
  margin-left:0px;
  margin-bottom: 0px;
  text-align: center;

}

.rank-points {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700; /* Makes the font bold */
  /* Add more styling here as needed */
  }

#klubbnavn {
    font-size: 35px;
    margin-top:0px;
    text-align: center;
  }

@media (max-width: 768px) {
  .player-detail-container {
    padding: 10px;
    max-width: 100%;
  }
  .chart-container {
    height: 250px; /* Adjust for smaller screens */
  }

  h1, p, .trend-container {
    font-size: smaller; /* Adjust font size for readability */
  }

  .rank-table th, .rank-table td {
    padding: 4px; /* Reduce padding to save space */
  }
}
.rank-table th {
  background-color: #ff79b0;
}

#name {
  font-size: 65px;
  margin-top:20px;
  text-align: center;
}
  /* Responsive Styles */
  @media (max-width: 768px) {
    .PlayerList {
      margin: 10px;
    }

    .input-container {
      padding-right: 0; /* Reset the padding-right you've set earlier */
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

    .PlayerList h1 {
      font-size: 30px;
      margin-bottom: 8px;
    }

    .PlayerList input {
      width: 100%;
    }
    #name {
      font-size: 50px;
    }

    .PlayerList h2 {
      font-size: 18px;
      margin-bottom: 8px;
    }

    .player-item .ranking {
      width: 20px;
    }

/* Main content */
.main-content {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center; /* This centers child elements horizontally */
  align-items: center; /* This centers child elements vertically */
  flex-direction: column; /* This makes child elements stack vertically */
}


  .searching-header {
    animation: rotation 2s infinite linear;
  }

  .rotating-emoji {
    display: inline-block;
    animation: rotation 2s infinite linear;
  }

  .club-logo {
    width: 100px; /* Smaller width for the club logo */
    height: 100px; /* Smaller height for the club logo */
    margin-right: 20px; /* Space between the club logo and the player image */
  }
  
  .player-image {
    width: 150px; /* Larger width for the player image */
    height: 150px; /* Larger height for the player image */
  }
  
  .info-container {
    display: flex;
    align-items: center;
    margin-left: 250px;
    margin-bottom: 30px;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

.button-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #e0e0e0;
  color: #000;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  transition: background-color 0.3s;
  cursor: pointer;
}

/* styles.css */
.category-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  margin-top:20px;
}

.category-buttons button {
  padding: 10px;
  cursor: pointer;
  background-color: var(--bg-200);
  color: var(--text-200);
  border: 1px solid black;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.category-buttons button:hover {
  background-color: #008119;
  color: #ffffff;
}
.player-detail-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}


.button-link {
  display: block;
  margin-bottom: 20px;
  color: #000;
  text-decoration: none;
}



p {
  font-size: 16px;
  margin-bottom: 10px;
}

.trend-container {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  animation: bounce 1s infinite;
  margin-bottom: 20px;
}

.trend-arrow {
  font-size: 30px;
  margin-right: 10px;
}

.green {
  color: green;
}

.red {
  color: red;
}
.neutral {
  color:gray;
}
.trend-message {
  margin-right: 10px;
}

.input-container {
  padding: 0;
  padding-right: 300px;
}

.rank-change {
  margin-left: 10px;
}

.chart-container {
  width: 100%;
  max-width: 800px;
  height: 400px;
  margin-bottom: 20px;
}

.rank-table {
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
}

.rank-table th,
.rank-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.player-detail-container {
  color:black;
}

@media (max-width: 600px) {
  h1 {
    font-size: 20px; /* Adjust h1 font size */
  }

  .category-buttons {
    margin-top: 0px;
  }

  p, .trend-container {
    font-size: 14px; /* Adjust font size for p and trend information */
  }

  .chart-container {
    height: 200px; /* Further adjust for very small screens */
  }
  .info-container {
    margin: auto;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }

  .trend-container {
    font-size: 14px;
  }

  .trend-arrow {
    font-size: 20px;
  }

  .chart-container {
    height: 250px;
  }

  .rank-table th,
  .rank-table td {
    padding: 4px;
  }
}