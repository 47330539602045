/* Default styles for larger screens */

.navbar {
  display: flex;
  justify-content: space-between; // Align items horizontally
  align-items: center;
  background-color: #0046a1;
  padding: 10px;
  color: #bb7777;
  width: 100%;
  z-index: 10;
  position: sticky;
  top: 0;
}




.navbar-brand {
  color: #EEEEEE;
  font-weight: 700;
  font-size: 1.5em;
  text-decoration: none;
}

.navbar-brand:active {
  color: #FF4081;
}


.navbar-brand:hover {
  color: #FF4081;
}

.navbar.bg-light {
  background-color: #333333 !important; // Use your desired color
}

.navbar-nav {
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav-item {
  margin: 0 10px;
}

.nav-link {
  color: #EEEEEE;
  text-decoration: none;
  font-weight: 500;
}


.navbar-toggler {
  border: none;
  background: none;
}




.dropdown-menu {
  background-color: #2c2f33;
}

.dropdown-item {
  color: #EEEEEE;
}

.dropdown-item:hover {
  background-color: #FF4081;
  color: #7289da;
}

.active {
  border-bottom: 2px solid #7289da;
  color:#ff79b0;
}

.nav-link:hover {
  color: #FF4081; /* Adjust if you want a different color on hover */
}


/* Media query for screens with max-width 991px */
@media screen and (max-width: 991px) {
  .navbar {
    flex-wrap: wrap;
    padding: 5px;
    position: sticky;
  }

  .navbar-toggler {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .navbar-collapse {
    flex-basis: 100%;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: center;
    margin: 5px 0;
  }

  .nav-item {
    margin: 5px 0;
  }
}

.nav-link:focus, .nav-link:active {
  color: #FF4081; /* Or any other color you prefer */
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7h20q0.828 0 0.828 0.828v1.344q0 0.828-0.828 0.828H5q-0.828 0-0.828-0.828V7.828Q4.172 7 5 7ZM5 14h20q0.828 0 0.828 0.828v1.344q0 0.828-0.828 0.828H5q-0.828 0-0.828-0.828v-1.344Q4.172 14 5 14ZM5 21h20q0.828 0 0.828 0.828v1.344q0 0.828-0.828 0.828H5q-0.828 0-0.828-0.828v-1.344Q4.172 21 5 21Z' fill='white'/%3E%3C/svg%3E");
}
