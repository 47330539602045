
.PlayerList {
  padding: 20px;
  margin-top: 100px; /* Adjust top margin */
  width: 90%; /* Make width relative to viewport */
  max-width: 500px; /* Maximum width */
  margin-left: auto; /* Center align the div */
  margin-right: auto; /* Center align the div */
  
  color: black;
}

.PlayerList h1 {
  font-size: 50px; /* Responsive font size */
  text-align: center;
  margin-bottom: 20px;
  margin-top: 110px;
}

/* Style the input with a more flexible approach */
.AsyncSelect__control {
  width: 100%; /* Ensure it takes up the full width of its parent */
}

/* Style adjustments for placeholders, options, and other elements remain largely the same, 
but you might want to adjust font sizes or padding in a similar manner to the h1 adjustments for responsiveness */

/* Responsive Font Sizes for Other Elements (example) */

/* Example media query for devices wider than 600px */
@media (min-width: 600px) {
  .PlayerList {
    width: 800px;
    /* Adjust padding, margin, etc., for larger screens */
  }
  
  /* Adjust other elements as needed */
}

/* Example media query for devices narrower than 600px */
@media (max-width: 599px) {
  .PlayerList {
    width: 350px;
    margin-top: 10%; /* Increase top margin for smaller screens */
  }
  .PlayerList h1 {
    font-size: 40px;
    margin-top:50%;
  }

  /* Adjust font sizes and padding for smaller devices */
}
