.leaderboard-container {
    width: 80%;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.leaderboard-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.select-container {
    text-align: center;
    margin-bottom: 20px;
}

.select-container select {
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    background-color: #fff;
    cursor: pointer;
}

.leaderboard-list li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    transition: background-color 0.3s ease;
}

.leaderboard-list li:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
}

.player-name {
    cursor: pointer; /* Make the mouse cursor change to pointer on hover over the player name */
    color: #007bff; /* Link-like blue color */
    text-decoration: none; /* No underline by default */
}

.player-name:hover {
    text-decoration: underline; /* Underline on hover for the player name */
    color: #0056b3; /* Optionally, a darker shade of blue on hover */
}

.leaderboard-list li a {
    color: #007bff; /* Bootstrap's default link color for reference, or use any color you prefer */
    text-decoration: none; /* Prevent underlining the links by default */
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.leaderboard-list li a:hover {
    text-decoration: underline; /* Underline links on hover to make it clear they are clickable */
    color: #0056b3; /* Darker shade of blue on hover for visual feedback, adjust as needed */
}

.leaderboard-list li:last-child {
    border-bottom: none;
}

.leaderboard-list li:nth-child(even) {
    background-color: #f9f9f9;
}

.leaderboard-list li:nth-child(odd) {
    background-color: #fff;
}

.leaderboard-list li:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
}
