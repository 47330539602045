/* Generelle stiler */
body, html{
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Oppdaterer skrifttypen for et mer moderne utseende */
  background: #f5f5f5; /* Lett bakgrunnsfarge for å gi siden et mykere utseende */
}

p {
  margin-bottom: 30px;
  margin-top: 10px;
}

h3 {
  margin-top: 20px;
}

.how-it-works-section {
  background-color: #ffffff; /* Lysere bakgrunn for å fremheve seksjonen */
  padding: 60px 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Legger til en subtil skygge for dybde */
  border-radius: 8px; /* Runde hjørner for et mykere utseende */
  margin: 20px auto; /* Sentrerer seksjonen med litt margin */
  max-width: 800px; /* Begrenser maksbredde for bedre lesbarhet på store skjermer */
}
/* Hero-seksjon */
.hero-section {
  background-color: #ffffff; /* Lysere bakgrunn for å fremheve seksjonen */
  padding: 60px 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Legger til en subtil skygge for dybde */
  border-radius: 8px; /* Runde hjørner for et mykere utseende */
  margin: 20px auto; /* Sentrerer seksjonen med litt margin */
  max-width: 800px; /* Begrenser maksbredde for bedre lesbarhet på store skjermer */
}

.about-section {
  background-color: #ffffff; /* Lysere bakgrunn for å fremheve seksjonen */
  padding: 60px 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Legger til en subtil skygge for dybde */
  border-radius: 8px; /* Runde hjørner for et mykere utseende */
  margin: 20px auto; /* Sentrerer seksjonen med litt margin */
  max-width: 800px; /* Begrenser maksbredde for bedre lesbarhet på store skjermer */
}
/* Start-knapp */
.start-button {
  text-decoration: none;
  margin: 20px auto 20px auto; /* Top, horizontal (auto centers), bottom */
  max-width: 45%;
  width: auto;
  background-color: #FF4081;
  color: #ffff;
  padding: 0px 10px; /* Add some padding for better clickability */
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  border-radius: 20px;
  display: block; /* Ensure block level to work with margin auto */
}

/* Seksjonsstiler */
section {
  padding: 50px 20px;
  text-align: center;
  margin-bottom: 20px; /* Legger til litt mer plass mellom seksjoner */
}

/* Fordeler og trinn */
.step, .benefits-section ul li {
  margin-bottom: 20px;
  list-style-type: none; /* Fjerner punkttegn */
}

.benefits-section ul {
  padding: 0;
}

/* Responsiv design */
@media (max-width: 768px) {
  .hero-section, .how-it-works-section, .benefits-section, .about-section, .contact-section {
    padding: 30px 15px;
  }

  .start-button {
    padding: 10px 20px;
  }
}

/* Fotnote */
.footer-section {
  background-color: #222;
  color: white;
  text-align: center;
  padding: 20px 10px;
  position: relative; /* Endret til 'relative' for å unngå at den alltid er synlig */
  width: 100%;
  margin-top: 20px; /* Legger til plass over fotnoten */
  border-radius: 0 0 8px 8px; /* Runde hjørner nederst */
}

.celebration-section {
  background-color: #f4add9;
  border: 2px dashed #c3930f;
  padding: 20px;
  margin: 20px auto; /* Centers the section horizontally */
  border-radius: 10px;
  text-align: center;
  width: 50%; /* Sets the width to 50% */
  animation: pulse 2s infinite;
}


.celebration-card h2 {
  font-size: 1.5rem;
  color: #000000;
  margin-bottom: 10px;
}

.celebration-card p {
  font-size: 1rem;
  color: #333;
}
@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.05);
  }
  100% {
      transform: scale(1);
  }
}